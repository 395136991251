<template>
  <div>
    <intro />

    <what />

    <limit />

    <news />

    <how />

    <why />

    <who />

    <sfs />

    <outro />
  </div>
</template>

<script>
import Intro from './home/Intro.vue';
import What from './home/What.vue';
import Limit from './home/Limit.vue';
import News from '../../commons/components/hos/News.vue';
import How from '../../commons/components/hos/How.vue';
import Why from '../../commons/components/hos/Why.vue';
import Who from '../../commons/components/hos/Who.vue';
import Outro from '../../commons/components/hos/Outro.vue';
import Sfs from '../../commons/components/sfs/Main.vue';

export default {
  components: {
    Intro,
    What,
    Limit,
    News,
    How,
    Why,
    Who,
    Outro,
    Sfs,
  },
};
</script>

<style lang="scss">
#app {
  background: #fff
    url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="600px" height="600px" viewBox="0 0 100 100"><path d="M5.5 12a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM6 46a2 2 0 10.001-3.999A2 2 0 006 46zM31.5 8a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm-3 30a2 2 0 10.001-3.999A2 2 0 0028.5 38zm16-10a2.5 2.5 0 000-5 2.5 2.5 0 000 5zM40 17.5a1 1 0 100-2 1 1 0 000 2zm-10 31a1 1 0 100-2 1 1 0 000 2zm-12.5-25a1 1 0 100-2 1 1 0 000 2zM77.53 24.742a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm-21.5-3.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm31.5 15.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-22.5 11.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm28-38a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-25 3.5a2 2 0 100-4 2 2 0 000 4zm23 35.5a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm-37-16a1 1 0 100-2 1 1 0 000 2zM29.5 74a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM8 70.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM39.5 86a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM17 97.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm28-38a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM20 63a2 2 0 10.001-3.999A2 2 0 0020 63zm23 35.5a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm-37-16a1 1 0 100-2 1 1 0 000 2zM77.53 74.242a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm-12.5 23.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm14.5-40a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm-3 30a2 2 0 100-4 2 2 0 000 4zm14.5 11a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm1.5-21a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm-4.5-10.5a1 1 0 100-2 1 1 0 000 2zm-34 15.5a1 1 0 100-2 1 1 0 000 2z" fill="%23e2e8f0" fill-opacity=".3"/></svg>');
  position: relative;
}

#more {
  /*
    li:nth-of-type(2) = Framacloud
    li:nth-of-type(3) = YunoHost
  */
  li:nth-of-type(2) {
    display: none;
  }
}

/* SFS */
#support {
  @media (min-width: 992px) { /* cat is hidden by the form below 992px */
    background:
      transparent
      url('../../public/img/gattino-galileo.png')
      no-repeat
      10% calc(100% + 40px);
  }

  .col-lg-7 .ombre {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  #f-sfs + div { /* #outro parent */
    background:
      transparent
      url('../../public/img/gattino-galileo.png')
      no-repeat
      10% calc(100% + 20px);
  }
}

/* Framanav */
#home #fp-alert {
  background: var(--f-d1);
}

.f-bs4 #f-footer {
  border: none !important;
}
</style>
