<template>
  <div>
    <section
      id="intro"
      class="main-row"
    >
      <b-container class="px-md-0">
        <b-row>
          <b-col
            md="7"
            lg="6"
          >
            <h1 v-html="$t('hos.intro.title')"></h1>
            <h2 v-html="$t('hos.intro.subtitle')"></h2>
            <ul class="buttons">
              <li>
                <b-dropdown
                  menu-class="mt-1 rounded-0 pb-0"
                  split
                  split-variant="primary"
                  size="lg"
                  variant="primary"
                  offset="-2"
                  @click="create()"
                >
                  <template v-slot:button-content>
                    <span v-html="$t('hos.intro.btn-create-a-calc')"></span>
                  </template>
                  <b-dropdown-form
                    style="width: 400px"
                    @submit="create"
                  >
                    <div class="px-3">
                      <b-form-group
                        label-for="calc-name"
                        :label="$t('hos.intro.name')"
                      >
                        <b-input-group
                          size="sm"
                        >
                          <b-form-input
                            id="calc-name"
                            v-model="name"
                            type="text"
                            maxlength="50"
                          />
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <div class="fb-g3 pt-2 pb-3 px-3">
                      <span
                        v-show="copy"
                        aria-hidden="true"
                        class="float-right mt-1 small text-success"
                        v-html="$t('hos.intro.copied')"
                      ></span>
                      <b-form-group
                        label-for="share-link"
                        :label="$t('hos.intro.link')"
                      >
                        <b-input-group
                          size="sm"
                        >
                          <b-form-input
                            id="share-link"
                            ref="share-link"
                            onclick="select()"
                            readonly
                            type="text"
                            :value="url"
                          />
                          <b-input-group-append>
                            <b-input-group-text>
                              <a
                                aria-hidden="true"
                                href="#"
                                :title="$t('hos.intro.copy')"
                                variant="fb-d0"
                                @click.prevent="copyLink"
                              >
                                <i class="fas fa-paste fc-g7"></i>
                              </a>
                            </b-input-group-text>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                      <div class="text-right">
                        <b-button
                          variant="primary"
                          size="sm"
                          :href="url"
                        >
                          <span v-html="$t('hos.intro.btn-create')"></span>
                        </b-button>
                      </div>
                    </div>
                  </b-dropdown-form>
                </b-dropdown>
              </li>
              <li>
                <a
                  class="btn btn-lg btn-outline-warning"
                  :href="$t('hos.data.docs')"
                  onclick="return false;"
                  @click="docs = true"
                >
                  <i class="fas fa-circle-play"></i>
                  <span v-html="$t('hos.intro.btn-tour')"></span>
                </a>
              </li>
            </ul>
          </b-col>
        </b-row>
        <b-row
          id="learn-more"
          aria-hidden="true"
          align-h="center"
          class="d-none d-sm-flex"
        >
          <input
            id="btn-learn-more"
            type="checkbox"
            class="sr-only"
            @change="$parent.$children[1].$refs['what']
              .scrollIntoView({ behavior: 'smooth' })"
          />
          <label
            for="btn-learn-more"
            class="btn btn-sm btn-link p-0"
          >
            <span v-html="$t('hos.intro.more', $t('hos.data'))"></span>
            <i class="fas fa-lg fa-chevron-down d-block fc-g8"></i>
          </label>
        </b-row>
      </b-container>
    </section>

    <modal-docs
      :link="$t('hos.data.docs')"
      :open="docs"
    />
  </div>
</template>

<script>
import ModalDocs from '../../../commons/components/ModalDocs.vue';

export default {
  components: {
    ModalDocs,
  },

  data() {
    /* Random alphanumeric name with 10 chars */
    const name = [...Array(10)].map(() => Math.random().toString(36)[3]).join('')
      .replace(/(.|$)/g, c => c[!Math.round(Math.random()) ? 'toString' : 'toLowerCase']());

    return {
      copy: false,
      docs: false,
      endpoint: 'https://lite.framacalc.org/',
      suffix: `-${Math.trunc(new Date().getTime() / 3600000).toString(36)}`,
      name,
    };
  },

  computed: {
    sname() {
      return this.$t(this.name, '-kL@').replace(/[.]/g, '');
    },

    url() {
      return `${this.endpoint}${this.sname}${this.suffix}`;
    },
  },

  methods: {
    create() {
      window.location = this.url;
    },

    copyLink() {
      const input = this.$refs['share-link'];
      input.select();
      document.execCommand('copy');
      this.copy = true;
      setTimeout(() => { this.copy = false; }, 2000);
    },
  },
};
</script>

<style lang="scss">
#intro {
  filter: drop-shadow(2px 5px 4px rgba(0, 0, 0, 0.2));
  padding: 1rem 0 3rem;
  position: relative;
  z-index: 1;

  @media (max-width: 480px) {
    max-height: 600px;
  }

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::before {
    background: var(--f-f7);
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);
    clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);

    @media (max-width: 768px) {
      -webkit-clip-path: polygon(0 0, 0 100%, 100% 93%, 100% 0);
      clip-path: polygon(0 0, 0 100%, 100% 93%, 100% 0);
    }
  }

  &::after {
    background: var(--f-g5);
    background-image: url('../../../public/img/intro.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);

    @media (max-width: 768px) {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 98%, 0 95%);
      clip-path: polygon(0 0, 100% 0, 100% 98%, 0 95%);
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  h1 {
    color: var(--f-g8);
    font-size: 5rem;
    font-weight: 300;
    margin: 4rem 0 3rem;

    @media (max-width: 992px) {
      font-size: 4rem;
      margin: 3rem 0 2rem;
    }

    @media (max-width: 768px) {
      font-size: 3rem;
      margin: 2rem 0 1.5rem;
    }
  }

  h2 {
    color: var(--f-g8);
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.25;
    margin: 0;

    @media (max-width: 992px) {
      max-width: 75%;
      font-size: 1.75rem;
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  #learn-more {
    label {
      color: var(--f-g8);
      background: none;
      text-decoration: none;
    }

    span {
      background: linear-gradient(180deg,#0000,#fff4,#0000);
      border-radius: 1rem;
      padding: .25rem ;
      font-size: .875rem;
    }

    input:checked ~ label {
      visibility: hidden;
    }
  }

  .buttons {
    margin: 4rem 0 5.5rem;
    padding: 0;
    list-style: none;
    max-width: 50%;
    text-align: center;

    @media (max-width: 992px) {
      max-width: 66%;
    }

    @media (max-width: 768px) {
      margin: 3rem 0 1.5rem;
      max-width: 50%;
    }

    > li {
      margin: 1.5rem 0;
    }

    .btn {
      font-weight: bold;

      i.fas {
        margin-right: .25rem;
      }

      &.dropdown-toggle {
        max-width: 2.5rem;
      }
    }

    .dropdown-menu {
      background-color: var(--f-g1);
    }

    .btn-primary.dropdown-toggle-split {
      filter: brightness(.9)
    }
  }

  .btn-group, .btn-outline-warning {
    width: 100%;
  }

  .btn-outline-warning {
    background: #fff;
    border-color: #fff;
    font-weight: bold;

    &:hover, &:focus, &:active {
      background: var(--f-o7);
      border-color: var(--f-o7);
      color: #fff;
    }
  }
}
</style>
